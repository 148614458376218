import React, { memo, useState, useCallback, useRef, useEffect } from 'react'
import classNames from 'classnames'
import classes from './ChatWindow.module.css'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg'
import moment from 'moment'
import botAvatar from '../../assets/images/bot-icon.svg'
import { useHubConnection, useMessageListener } from '../../hooks/signalr'
import { LogLevel } from '@microsoft/signalr'
import { v4 as uuid } from 'uuid'
import {
  markTestTaken,
  canTakeTest,
  getTimeTestWasTaken,
  HOURS_BETWEEN_TESTS,
} from '../../utils/rateLimit'
import { ChatMessages } from './ChatMessages'
import { ChoiceInput, InputBox } from './Inputs'
import { ChipButton } from './Chips'

const mockMessages = [
  {
    sent: true,
    message: 'Hello, I have an incidence to report',
    deliveryStatus: 'read',
    date: moment().subtract(2, 'minutes'),
  },
  {
    sent: false,
    message: 'Welcome friend',
    date: moment().subtract(1.9, 'minutes'),
  },
  {
    sent: false,
    message: 'What is the incidence about?',
    date: moment().subtract(1.8, 'minutes'),
  },
  {
    sent: true,
    message:
      'I think there is a covid-19 patient in my vicinity, she has been coughing for a while',
    deliveryStatus: 'read',
    date: moment().subtract(1.5, 'minutes'),
  },
  {
    sent: false,
    message: 'Welcome friend',
    date: moment().subtract(1.4, 'minutes'),
  },
  {
    sent: false,
    message: 'What is the incidence about?',
    date: moment().subtract(1.3, 'minutes'),
  },
  {
    sent: false,
    message: 'Welcome friend',
    date: moment().subtract(1.4, 'minutes'),
  },
  {
    sent: false,
    message: 'What is the incidence about?',
    date: moment().subtract(1.3, 'minutes'),
  },
  {
    sent: false,
    message: 'Welcome friend',
    date: moment().subtract(1.4, 'minutes'),
  },
  {
    sent: false,
    message: 'What is the incidence about?',
    date: moment().subtract(1.3, 'minutes'),
  },
  {
    sent: false,
    message: 'Welcome friend',
    date: moment().subtract(1.4, 'minutes'),
  },
  {
    sent: false,
    message: 'What is the incidence about?',
    date: moment().subtract(1.3, 'minutes'),
  },
]

const TopBanner = memo(({ botOnline = true, hideWindow }) => (
  <div className={classes.topBanner}>
    <img src={botAvatar} className={classes.topBannerIcon} alt="" />
    <div className={classes.topBannerText}>
      <h5 className={classes.topBannerTitle}>GloepidBot</h5>
      <p className={classes.topBannerBotStatus}>
        {botOnline ? 'Online' : 'Offline'}
      </p>
    </div>
    <button className={classes.closeButtonMobile} onClick={hideWindow}>
      <CloseIcon />
    </button>
  </div>
))

const CloseButton = memo(
  ({ className, windowHidden, toggleWindowHidden, ...props }) => (
    <button
      className={classNames(
        classes.closeButton,
        windowHidden && classes.closeButtonHide,
        className
      )}
      {...props}
      onClick={toggleWindowHidden}
    >
      <CloseIcon />
      <EmailIcon className={classes.closeButtonEmailIcon} />
    </button>
  )
)

const ChatAreaOverlay = ({ visible, children }) => (
  <div
    className={classNames(classes.overlay, visible && classes.overlayVisible)}
  >
    {children}
  </div>
)

const LoadingOverlay = ({ visible = false }) => (
  <ChatAreaOverlay visible={visible}>
    <div className={classes.spinner} />
  </ChatAreaOverlay>
)

const ErrorOverlay = ({ visible = false, error }) => (
  <ChatAreaOverlay visible={visible}>
    <CloseIcon className={classes.errorIcon} />
    <p className={classes.errorText}>{error}</p>
  </ChatAreaOverlay>
)

const BotStatus = Object.freeze({
  AwaitingUserConsent: 'awaiting-user-consent',
  Conversing: 'conversing',
  ConversationEnded: 'conversation-ended',
})

export const BotEvent = Object.freeze({
  TriggerBot: 'gloepid/trigger-bot',
})

const canUserTakeTest = canTakeTest()
const HUB_URL = process.env.REACT_APP_HUB_URL

export default function ChatWindow({
  onWindowStateChange = null,
  configuration = null,
}) {
  const [messages, setMessages] = useState(() => {
    if (canUserTakeTest) return []
    else {
      const timeFromNow = moment(getTimeTestWasTaken())
        .add(HOURS_BETWEEN_TESTS, 'hours')
        .fromNow()
      return [
        {
          id: uuid(),
          date: new Date(),
          message:
            "You have already self assessed your self recently, there's no need to repeat the assessment.",
        },
        {
          id: uuid(),
          date: new Date(),
          message: `You will be able to take the test again ${timeFromNow}`,
        },
      ]
    }
  })
  // eslint-disable-next-line
  const mockQuestionInfo = {
    questionId: 0,
    intentName: 'NameProvider',
    quest: "What's your name",
    options: ['Fever', 'Cough', 'Difficulty Breathing'],
    optionsNextId: [1, 0], // [yes (default next id), no] or [option1, option2, option3, etc]
    hasOptions: true,
    isMultipleChoice: true,
  }
  const [questionInfo, setQuestionInfo] = useState({})
  const [showTypingIndicator, setShowTypingIndicator] = useState(false)
  const [botStatus, setBotStatus] = useState(BotStatus.AwaitingUserConsent)
  const showLocationPrompt =
    process.env.REACT_APP_STAGE !== 'production' &&
    navigator.geolocation &&
    questionInfo &&
    questionInfo.quest &&
    (questionInfo.quest.toLowerCase().includes('location') ||
      questionInfo.quest.toLowerCase().includes('where'))

  const chatAreaRef = useRef()
  const typingIndicatorTimeoutRef = useRef()

  const onConnectedCallback = useCallback(
    (connection) => {
      const args = ['web']
      if (configuration && configuration.partnerId)
        args.push(configuration.partnerId)
      connection.invoke('SelfIdentify', ...args)
    },
    [configuration]
  )

  const { connection, connectionState } = useHubConnection(
    HUB_URL,
    process.env.REACT_APP_STAGE === 'production'
      ? LogLevel.None
      : LogLevel.Debug,
    onConnectedCallback
  )

  const receiveMessage = useCallback((message, info) => {
    if (!canUserTakeTest) return
    if (typingIndicatorTimeoutRef.current) {
      clearTimeout(typingIndicatorTimeoutRef.current)
      setShowTypingIndicator(false)
      typingIndicatorTimeoutRef.current = null
    }
    setQuestionInfo({
      ...info,
      optionsNextId: info.optionsNextId.map((x) => parseInt(x)),
    })
    const id = uuid()
    setMessages((messages) => [
      ...messages,
      {
        id,
        sent: false,
        message,
        date: new Date(),
      },
    ])
  }, [])

  useMessageListener(connection, 'ReceiveResponse', receiveMessage)

  const closeConnection = useCallback(
    (endOfConversation = true) => {
      connection.stop()
      setBotStatus(BotStatus.ConversationEnded)
      if (endOfConversation) markTestTaken()
    },
    [connection]
  )

  useMessageListener(connection, 'CloseConnection', closeConnection)

  const sendMessage = ({
    choice,
    message,
    selectedIndexes,
    options,
    singleChoice,
  }) => {
    if (!connection) return
    const id = uuid()
    const selectedOptions = choice
      ? // -1 === None
        selectedIndexes.map((selectedIndex) =>
          selectedIndex === -1 ? 'None' : options[selectedIndex]
        )
      : []
    let sentMessage = choice ? selectedOptions.join(', ') : message
    setMessages((messages) => [
      ...messages,
      {
        id,
        sent: true,
        message: sentMessage,
        deliveryStatus: 'sent',
        date: new Date(),
      },
    ])
    // If we don't get a response back within 0.1s, show a typing indicator
    typingIndicatorTimeoutRef.current = setTimeout(() => {
      setShowTypingIndicator(true)
    }, 100)
    // Arguments schema:
    // string[] selectedOptions, string message, int questionId, int nextQuestionId
    // selectedOptions is null for non-choice questions, message isnt. And vice versa.
    let args = [
      [],
      message,
      questionInfo.questionId,
      questionInfo.optionsNextId[0],
    ]
    if (choice) {
      if (singleChoice) {
        args = [
          selectedOptions,
          null,
          questionInfo.questionId,
          questionInfo.optionsNextId[selectedIndexes[0]],
        ]
      } else {
        const lastIndex = questionInfo.optionsNextId.length - 1
        args = [
          selectedOptions,
          null,
          questionInfo.questionId,
          selectedIndexes.length > 2
            ? questionInfo.optionsNextId[0]
            : questionInfo.optionsNextId[lastIndex],
        ]
      }
    }
    connection
      .invoke('SendResponse', ...args)
      .then(() => {
        setMessages((messages) => {
          const newState = [...messages]
          const message = messages.find((message) => message.id === id)
          message.deliveryStatus = 'read'
          return newState
        })
      })
      .catch((err) => {
        if (process.env.REACT_APP_STAGE !== 'production') console.warn(err)
        setMessages((messages) => {
          const newState = [...messages]
          const message = messages.find((message) => message.id === id)
          message.deliveryStatus = 'error'
          return newState
        })
      })
      .finally(() => {
        if (typingIndicatorTimeoutRef.current) {
          clearTimeout(typingIndicatorTimeoutRef.current)
          setShowTypingIndicator(false)
          typingIndicatorTimeoutRef.current = null
        }
      })
  }

  const clientSideMessage = (message) => {
    const id = uuid()
    setMessages((messages) => [
      ...messages,
      {
        id,
        sent: false,
        message,
        date: new Date(),
      },
    ])
  }

  const receiveWelcomeMessages = useCallback(
    (welcomeMessages, initialQuestionInfo) => {
      if (!canUserTakeTest) return
      welcomeMessages.forEach((message) => clientSideMessage(message))
      setQuestionInfo({
        ...initialQuestionInfo,
        optionsNextId: initialQuestionInfo.optionsNextId.map((x) =>
          parseInt(x)
        ),
      })
    },
    []
  )

  useMessageListener(connection, 'WelcomeMessage', receiveWelcomeMessages)

  const startConveration = () => {
    clientSideMessage(questionInfo.quest)
    setBotStatus('conversing')
  }

  const promptForLocation = () => {
    clientSideMessage('Acquiring your location...')
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { longitude, latitude } = position.coords
        //
        let args = [
          ['' + longitude, '' + latitude],
          null,
          questionInfo.questionId,
          questionInfo.optionsNextId[0],
        ]
        connection.invoke('SendResponse', ...args).catch((err) => {
          if (process.env.REACT_APP_STAGE !== 'production') console.warn(err)
          clientSideMessage(
            'I was unable to retrieve your location. Kindly use the text input instead.'
          )
        })
      },
      (error) => {
        if (process.env.REACT_APP_STAGE !== 'production') console.warn(error)
        let message =
          'I was unable to retrieve your location. Kindly use the text input instead.'
        if (error.code === window.GeolocationPositionError.PERMISSION_DENIED)
          message =
            'You need to grant me access to your location so I can know where you are. Please try again.'
        clientSideMessage(message)
      },
      {
        enableHighAccuracy: true,
        maximumAge: 0,
      }
    )
  }

  useEffect(() => {
    const { current } = chatAreaRef
    if (current) {
      current.scrollTop = current.scrollHeight
    }
  }, [messages, showTypingIndicator])

  // If the user can't take the test, disconnect once connected
  useEffect(() => {
    if (connectionState.status === 'CONNECTED' && !canUserTakeTest)
      closeConnection(false)
  }, [connectionState.status, closeConnection])

  const [windowHidden, setWindowHidden] = useState(false)
  useEffect(() => {
    if (typeof onWindowStateChange === 'function')
      onWindowStateChange(windowHidden)
  }, [windowHidden, onWindowStateChange])

  useEffect(() => {
    const showBot = () => setWindowHidden(false)
    window.addEventListener(BotEvent.TriggerBot, showBot)
    return () => {
      window.removeEventListener(showBot)
    }
  }, [])

  return (
    <div className={classes.chatWindowWrapper}>
      <div
        className={classNames(
          classes.chatWindow,
          windowHidden && classes.chatWindowHide
        )}
      >
        <TopBanner
          botOnline={connectionState.status === 'CONNECTED'}
          hideWindow={() => setWindowHidden(true)}
        />
        <ChatMessages
          ref={chatAreaRef}
          messages={messages}
          showTypingIndicator={showTypingIndicator}
          // Disable this in production for now, it's incomplete
          showConversationEndPrompts={
            process.env.REACT_APP_STAGE !== 'production' &&
            botStatus === BotStatus.ConversationEnded
          }
        />
        <div
          className={classNames(
            classes.inputBox,
            botStatus === BotStatus.ConversationEnded &&
              classes.inputBoxCollapsed
          )}
        >
          {showLocationPrompt && (
            <div className={classes.chipList}>
              <ChipButton onClick={promptForLocation}>
                Use current location
              </ChipButton>
            </div>
          )}
          {botStatus === BotStatus.Conversing &&
            (questionInfo && questionInfo.hasOptions ? (
              <ChoiceInput
                options={questionInfo.options}
                onSendMessage={sendMessage}
                multipleChoice={questionInfo.isMultipleChoice}
              />
            ) : (
              <InputBox
                disabled={connectionState.status !== 'CONNECTED'}
                onSendMessage={sendMessage}
              />
            ))}
          {botStatus === BotStatus.AwaitingUserConsent && (
            <ChipButton onClick={startConveration}>I am ready</ChipButton>
          )}
        </div>
        <LoadingOverlay
          key="loading"
          visible={connectionState.status === 'CONNECTING'}
        />
        <ErrorOverlay
          key="error"
          visible={connectionState.status === 'ERROR'}
          error={`An error occured while connecting${
            connectionState.error && ': ' + connectionState.error.message
          }`}
        />
      </div>
      <CloseButton
        windowHidden={windowHidden}
        toggleWindowHidden={() => setWindowHidden(!windowHidden)}
      />
    </div>
  )
}
