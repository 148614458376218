import React from 'react'
import ChatWindow from './ChatWindow/ChatWindow'
import { Route } from 'react-router-dom'

export default function RouteWithBot({
  path,
  exact = false,
  component: Component,
  render,
  ...props
}) {
  const wrappedRender = (props) => {
    return (
      <React.Fragment>
        {render(props)}
        <ChatWindow />
      </React.Fragment>
    )
  }
  const renderComponent = (props) => {
    return (
      <React.Fragment>
        <Component {...props} />
        <ChatWindow />
      </React.Fragment>
    )
  }

  return (
    <Route
      path={path}
      exact={exact}
      render={Component ? renderComponent : wrappedRender}
      {...props}
    />
  )
}
