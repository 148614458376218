import React from 'react'
import classes from './footer.module.css'
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg'
import { ReactComponent as FacebookIconFilled } from '../../assets/icons/facebook-filled.svg'
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg'
import { ReactComponent as TwitterIconFilled } from '../../assets/icons/twitter-filled.svg'
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg'
import { ReactComponent as InstagramIconFilled } from '../../assets/icons/instagram-filled.svg'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <div className={classes.footerLinks}>
        <Link to={'/about'} className={classes.footerLink}>
          About Us
        </Link>
        <Link to={'#'} className={classes.footerLink}>
          Covid Visualizer
        </Link>
        <Link to={'/contact'} className={classes.footerLink}>
          Contact Us
        </Link>
      </div>
      <div className={classes.footerIcons}>
        <span className={classes.footerIcon}>
          <FacebookIcon className={classes.footerIconSvg} />
          <FacebookIconFilled
            className={classNames(
              classes.footerIconSvg,
              classes.footerIconSvgHoverShow
            )}
          />
        </span>
        <span className={classes.footerIcon}>
          <TwitterIcon className={classes.footerIconSvg} />
          <TwitterIconFilled
            className={classNames(
              classes.footerIconSvg,
              classes.footerIconSvgHoverShow
            )}
          />
        </span>
        <span className={classes.footerIcon}>
          <InstagramIcon className={classes.footerIconSvg} />
          <InstagramIconFilled
            className={classNames(
              classes.footerIconSvg,
              classes.footerIconSvgHoverShow
            )}
          />
        </span>
      </div>
    </footer>
  )
}

export default Footer
