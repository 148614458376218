import React from 'react'
import classes from './PassionCard.module.css'
import { ReactComponent as BulbIconCorner } from '../../assets/icons/bulb-corner.svg'
import { ReactComponent as BulbIconBottom } from '../../assets/icons/bulb-bottom.svg'
import { ReactComponent as BulbIconRight } from '../../assets/icons/bulb-right.svg'
import classNames from 'classnames'

export const PassionCardIconVariants = Object.freeze({
  RIGHT: (
    <BulbIconRight
      className={classNames(classes.cardIconRight, classes.cardIcon)}
    />
  ),
  BOTTOM: (
    <BulbIconBottom
      className={classNames(classes.cardIconBottom, classes.cardIcon)}
    />
  ),
  CORNER: (
    <BulbIconCorner
      className={classNames(classes.cardIconCorner, classes.cardIcon)}
    />
  ),
})

const PassionCard = ({ title, description, variant }) => {
  return (
    <div className={classes.card}>
      <h1 className={classes.cardTitle}>{title}</h1>
      <p className={classes.cardDetails}>{description}</p>
      {variant}
    </div>
  )
}

export default PassionCard
