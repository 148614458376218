import React from 'react'
import gridClasses from '../../styles/grid.module.css'
import classes from './AboutUs.module.css'
import { Header } from '../../components/Header/Header'
import TeamMember from '../../components/TeamMember/TeamMember'
import classNames from 'classnames'
import tower from '../../assets/images/tower.png'
import Footer from '../../components/Footer/Footer'
import { GetTheAppCard } from '../../components/Cards/GetTheAppCard/GetTheAppCard'
import showcaseImg from '../../assets/images/about-bg.png'
import Team from './TeamMock'
import microsoft from '../../assets/images/microsoft.svg'
import tech4dev from '../../assets/images/tech4dev.svg'
import prunedge from '../../assets/images/prunedge.svg'
import vp from '../../assets/images/vp.svg'

const partnerLogo = [
  {
    logoUrl: microsoft,
    name: 'Microsoft',
  },
  {
    logoUrl: prunedge,
    name: 'Prunedge',
  },
  {
    logoUrl: vp,
    name: 'vp',
  },
  {
    logoUrl: tech4dev,
    name: 'Tech4dev',
  },
]

const AboutUs = () => {
  return (
    <>
      <section className={classes.showcase}>
        <Header />
        <img
          src={showcaseImg}
          alt="Background"
          className={classes.showcaseBg}
        />
        <section className={classNames(classes.aboutUs, gridClasses.container)}>
          <div className={classes.sectionGrid}>
            <img src={tower} alt="tower" className={classes.tower} />
            <div className={classes.gridText}>
              <h2 className={classes.gridTextHeader}>
                Why we are keen on predicting the next global epidemic in a
                smart way.
              </h2>
              <p className={classes.gridTextSubtext}>
                Infectious diseases pose a significant risk to human health;
                some of them are re-emerging while others are novel. Even though
                outbreaks of infectious diseases can be prevented with vaccines,
                some of them can have devastating effects in communities with
                limited health infrastructure and resources, as well as places
                where timely detection and response is difficult. The Ebola
                virus outbreak, COVID-19 pandemic amongst others, has resulted
                in immeasurable losses, from lives to jobs, and nearly crippled
                the economies in some countries.
              </p>
              <p className={classes.gridTextSubtext}>
                Outbreaks will be easier to contain and prevented from spreading
                if they are discovered early enough. One of the most effective
                solutions to problems like this is using a prediction model to
                plan and make informed decisions, which is what GloEpid aims to
                achieve. By offering early detection of potential epidemic
                outbreaks, GloEpid is committed to improving the state of
                community health around the world and help keep businesses,
                families, and communities safe.
              </p>
              <p className={classes.gridTextSubtext}>
                GloEpid is a non-profit volunteer initiative working to monitor
                and respond to epidemic invasions in the world before they get
                out of control. It is a two-part tracking and surveillance
                system that uses contact tracing, self-assessment, and reporting
                to quickly track travel and contact information to stop the
                spread of viruses. It will be advantageous in the early
                detection of future epidemics that may appear, as well as in the
                connection and real-time dissemination of information that can
                help contain or prevent epidemic crises and lessen the burden on
                our healthcare systems while saving lives.
              </p>
            </div>
          </div>
        </section>
        <section
          className={classNames(classes.partnerLogos, gridClasses.container)}
        >
          {partnerLogo.map((data, index) => (
            <img
              src={data.logoUrl}
              alt={data.name}
              className={classes.partnerLogo}
              key={index}
            />
          ))}
        </section>
      </section>
      <section className={classes.teamSection}>
        <div
          className={classNames(
            classes.teamSectionContent,
            gridClasses.container
          )}
        >
          <h1 className={classes.teamSectionText}>
            We are a team of changemakers passionate about solving the world’s
            greatest challenges through technology.
          </h1>
          <h3 className={classes.teamSectionSubText}>
            We are vibrant, diverse and agile; these qualities and many others
            are the sources of our strength; they shape our views and guide our
            actions as we work collectively to #beatCOVID-19.
          </h3>
          <div className={classes.teamCards}>
            {Team.map((data, index) => (
              <TeamMember
                memberName={data.name}
                designation={data.title}
                key={index}
              />
            ))}
          </div>
        </div>
      </section>
      <section className={classes.getTheApp}>
        <GetTheAppCard />
      </section>
      <Footer />
    </>
  )
}

export default AboutUs
