import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomePage from './routes/Home/Home'
import LandingPage from './routes/Landing/Landing'
import MobileDetails from './routes/MobileDetails/MobileDetails'
import Contact from './routes/Contact/Contact'
import AboutUs from './routes/AboutUs/AboutUs'
import BotOnly from './routes/BotOnly/BotOnly'
import RouteWithBot from './components/RouteWithBot'

function App() {
  return (
    <Router>
      <Switch>
        <RouteWithBot path="/" exact component={LandingPage} />
        {process.env.REACT_APP_STAGE !== 'production' && (
          <RouteWithBot path="/old-home" component={HomePage} />
        )}
        <RouteWithBot path="/mobile-details" component={MobileDetails} />
        <RouteWithBot path={'/contact'} component={Contact} />
        <RouteWithBot path="/about" component={AboutUs} />
        <Route path="/bot" component={BotOnly} />
      </Switch>
    </Router>
  )
}

export default App
