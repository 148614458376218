import React from 'react'
import classes from './TeamMember.module.css'
import classNames from 'classnames'

/**
 * @description Card for displaying team member info
 *
 * @param {string} imageUrl - Image url of a team member
 * @param {string} memberName - Name of a team member
 * @param {string} designation - e.g Project Lead
 *
 * @returns {JSX}
 */
const TeamMember = ({ imageUrl, memberName, designation }) => {
  return (
    <div className={classes.cardWrapper}>
      <div className={classes.card}>
        <img src={imageUrl} alt="" className={classes.cardImage} />
      </div>
      <p className={classes.memberName}>{memberName}</p>
      <p className={classes.designation}>{designation}</p>
    </div>
  )
}

export default TeamMember
