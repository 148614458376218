import React from 'react'
import classes from './Home.module.css'
import gridClasses from '../../styles/grid.module.css'
import logo from '../../assets/images/logo.svg'
import { ReactComponent as AppStore } from '../../assets/images/app-store.svg'
import { ReactComponent as PlayStore } from '../../assets/images/play-store.svg'

function HomePage() {
  return (
    <div className={gridClasses.container}>
      <img src={logo} alt="Gloepid" className={classes.logo} />
      <div className={classes.pageContent}>
        <div className={classes.pageText}>
          <h1 className={classes.pageTitle}>
            Self-assess yourself and know your COVID-19 exposure risk levels
            with our Smartbot
          </h1>
          <p className={classes.pageSubtitle}>
            Do you feel any symptoms and need to understand what you are
            feeling? Take a test and find out.
          </p>
          <div className={classes.appLinks}>
            <a
              href="https://www.apple.com/ios/app-store/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.appLink}
              aria-label="Download the iOS app"
            >
              <AppStore />
            </a>
            <a
              href="https://play.google.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.appLink}
              aria-label="Download the Android app"
            >
              <PlayStore />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage
