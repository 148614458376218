const Team = [
  { name: 'Joel Ogunsanya', title: 'Project Manager' },
  { name: 'Joel Ogunsanya', title: 'Project Manager' },
  { name: 'Joel Ogunsanya', title: 'Project Manager' },
  { name: 'Joel Ogunsanya', title: 'Project Manager' },
  { name: 'Joel Ogunsanya', title: 'Project Manager' },
  { name: 'Joel Ogunsanya', title: 'Project Manager' },
  { name: 'Joel Ogunsanya', title: 'Project Manager' },
  { name: 'Joel Ogunsanya', title: 'Project Manager' },
]

export default Team
