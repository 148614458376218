import React from 'react'
import classNames from 'classnames'
import gridClasses from '../../styles/grid.module.css'
import classes from './Landing.module.css'
import { Header } from '../../components/Header/Header'
import PassionCard, {
  PassionCardIconVariants,
} from '../../components/PassionCard/PassionCard'
import phoneImage from '../../assets/images/landing-page-phone.png'
import imageTop from '../../assets/images/landing-image-top.png'
import imageBottom from '../../assets/images/landing-image-bottom.png'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import { BotEvent } from '../../components/ChatWindow/ChatWindow'
import { ReactComponent as AppStoreLight } from '../../assets/images/app-store-light.svg'
import { ReactComponent as PlayStoreLight } from '../../assets/images/play-store-light.svg'
import { ChatWithBotCard } from '../../components/Cards/ChatWithBotCard/ChatWithBotCard'

const partners = [
  {
    name: 'Microsoft',
    logo: require('../../assets/images/logos/microsoft.svg'),
  },
  {
    name: 'Prunedge',
    logo: require('../../assets/images/logos/prunedge.svg'),
  },
  {
    name: 'Ventures Platform',
    logo: require('../../assets/images/logos/ventures-platform.png'),
  },
  {
    name: 'Tech4Dev',
    logo: require('../../assets/images/logos/tech4dev.svg'),
    className: classes.tech4devLogo,
  },
]

export default function LandingPage() {
  const triggerBot = () => {
    const event = new CustomEvent(BotEvent.TriggerBot)
    window.dispatchEvent(event)
  }
  return (
    <React.Fragment>
      <div className={classes.hero}>
        <Header />
        <div
          className={classNames(gridClasses.container, classes.heroContainer)}
        >
          <div className={classes.heroContent}>
            <h1 className={classes.heroTitle}>
              GloEpid is a Non-Profit Organization focused on using technology
              to predict and prevent the next world epidemy.
            </h1>
            <p className={classes.heroSubtitle}>
              The GloEpid tool helps combat epidemics by tracking and tracing
              confirmed cases to the regions and/or persons who may have come in
              contact with the carriers.
            </p>
          </div>
          <div className={classes.heroImagesContainer}>
            <div className={classes.heroImages}>
              <img
                src={imageTop}
                className={classNames(classes.heroImage, classes.heroImageTop)}
                alt=""
              />
              <img
                src={imageBottom}
                className={classNames(
                  classes.heroImage,
                  classes.heroImageBottom
                )}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <main>
        <section className={classes.pageSection}>
          <div className={gridClasses.container}>
            <h1 className={classes.sectionTitle}>
              Our passion and enthusiasm are driven by these factors;
            </h1>
            <div className={classes.passionCards}>
              <PassionCard
                title={'Humanity'}
                description={
                  'GloEpid was developed by sympathetic people who care about a world in need. We concentrate our time, energy, and resources on recognizing and celebrating the human experience because we believe that by working together, we can make the world free from epidemy for everyone.'
                }
                variant={PassionCardIconVariants.CORNER}
              />
              <PassionCard
                title={'Technology'}
                description={
                  'We have developed a technological solution to anticipate any epidemic and aid decision-making because we believe technology can be used to address some of the world’s most pressing issues.'
                }
                variant={PassionCardIconVariants.RIGHT}
              />
              <PassionCard
                title={'Value'}
                description={
                  'We are committed to creating value.  As a community, we are driven to succeed in flattening the curve and predicting the spread of any epidemic. We are working innovatively to develop strategies that will stop the spread and offer real-time information sharing that can help contain or stop the spread.'
                }
                variant={PassionCardIconVariants.BOTTOM}
              />
            </div>
          </div>
        </section>
        <section
          className={classNames(
            classes.pageSection,
            classes.pageSectionPrimary
          )}
        >
          <div className={classNames(gridClasses.container, classes.panes)}>
            <div className={classes.imagePane}>
              <img
                src={phoneImage}
                className={classes.phoneImage}
                alt="Screenshot of Gleopid Mobile App"
              />
              <div className={classes.appLinks}>
                <a
                  href="https://play.google.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.appLink}
                  aria-label="Download the Android app"
                >
                  <PlayStoreLight />
                </a>
                <a
                  href="https://www.apple.com/ios/app-store/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.appLink}
                  aria-label="Download the iOS app"
                >
                  <AppStoreLight />
                </a>
              </div>
            </div>
            <div className={classes.appDescription}>
              <div className={classes.sectionTitleGroup}>
                <h5 className={classes.sectionTitleLabel}>Mobile App</h5>
                <h1 className={classes.sectionTitle}>
                  Download the App, take a Test
                </h1>
                <p className={classes.featureListItemDescription}>
                  In order to battle COVID19 through self-test, we initially
                  introduced the GloEpid solution, which was a major success and
                  was quickly adopted by state governments to assist their
                  medical teams in more effectively addressing the pandemic
                  based on urgency and severity.
                </p>
              </div>
              <ol className={classes.featureList}>
                <li className={classes.featureListItem}>
                  <h5 className={classes.sectionSpan}>
                    Some features from our mobile app:
                  </h5>
                  <h6 className={classes.featureListItemTitle}>
                    Fast Contact Tracing
                  </h6>
                  <p className={classes.featureListItemDescription}>
                    Using Bluetooth and GPS, other devices with the app
                    installed within a range are identified and stored as an
                    activity log with timestamps and location points. With your
                    permission, this Information is made available to the
                    relevant authorities if it is necessary.
                  </p>
                </li>
                <li className={classes.featureListItem}>
                  <h6 className={classes.featureListItemTitle}>Data Privacy</h6>
                  <p className={classes.featureListItemDescription}>
                    All data is encrypted and stored on your device and cannot
                    be accessed unless you are identified as a close contact or
                    confirmed case of a virus and have given consent.
                  </p>
                </li>
                <li className={classes.featureListItem}>
                  <h6 className={classes.featureListItemTitle}>
                    Self-Assessment Test
                  </h6>
                  <p className={classes.featureListItemDescription}>
                    Take assessment tests in-app, know your Risk factor levels,
                    follow the suggestions and have your report sent directly to
                    the relevant authorities for follow-up activities.
                  </p>
                </li>
              </ol>
              <Link to="/mobile-details" className={classes.learnMoreLink}>
                Learn More
              </Link>
            </div>
          </div>
        </section>
        <section className={classes.pageSection}>
          <div className={gridClasses.container}>
            <ChatWithBotCard
              onButtonClick={triggerBot}
              className={classes.chatCard}
            />
            <h5 className={classes.partnersSectionTitle}>
              Our amazing partners
            </h5>
            <ul className={classes.partnersList}>
              {partners.map((partner, index) => (
                <li
                  key={index}
                  className={classNames(
                    classes.partnersListItem,
                    partner.className
                  )}
                >
                  <img src={partner.logo} alt={partner.name} />
                </li>
              ))}
            </ul>
          </div>
        </section>
      </main>
      <Footer />
    </React.Fragment>
  )
}
