import React from 'react'
import classNames from 'classnames'
import classes from './ChatWithBotCard.module.css'
import { Button } from '../../Form/Button'

export const ChatWithBotCard = ({ className, onButtonClick, ...props }) => (
  <div className={classNames(classes.chatWithBotCard, className)} {...props}>
    <h3 className={classes.title}>
      Are you currently overwhelmed about the pandemic and would need to take
      the self assessment test? Kindly click the button below.
    </h3>
    <Button onClick={onButtonClick}>Chat now</Button>
  </div>
)
